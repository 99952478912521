/* @import '~antd/dist/antd.css'; */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Porsche Next', 'Arial Narrow', 'Arial', 'Microsoft YaHei',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  margin-top: 24px;
  background-color: #fff;
}


/* 单行超出显示省略号 */
.singleline-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 多行超出显示省略号 */
.multiline-ellipsis {
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.notification-ellipsis {
  line-clamp: 3;
  -webkit-line-clamp: 3;
}

iframe {
  border: none;
}

.ant-table-title {
  padding-left: 0;
}

.ant-card-head-title,
.ant-modal-title,
.ant-table-title {
  font-family: 'Porsche Next SemiBold';
}

.ant-table-thead {
  .ant-table-cell {
    font-family: 'Porsche Next SemiBold';
  }
}

#webpack-dev-server-client-overlay {
  display: none !important;
}
